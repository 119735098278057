export const reducer = (state, action) => {
	switch (action.type) {
	case 'SET_SLIDER_VALUES': {
		return {
			...state,
			sliderValues: action.payload
		}
	}
	case 'SET_FILTER': {

		const nextCurrentFilters = {
			...state.currentFilters,
		}

		for (let prop in action.payload) {
			if (action.payload[prop]) {
				nextCurrentFilters[prop] = action.payload[prop]
			} else {
				delete nextCurrentFilters[prop]
			}
		}

		return {
			...state,
			currentFilters: nextCurrentFilters,
		}
	}
	case 'RESET_FILTERS': {
		return {
			...state,
			sliderValues: [0, 100],
			currentFilters: {},
		}
	}
	case 'SET_CURRENT_ENTRIES': {
		return {
			...state,
			currentEntries: action.payload,
			// currentEntry: action.payload[0],
		}
	}
	case 'SET_CURRENT_ENTRY': {
		return {
			...state,
			currentEntry: action.payload,
		}
	}
	case 'SET_DETAILS_OPEN': {
		return {
			...state,
			detailsOpen: action.payload,
		}
	}
	case 'SET_HEADER_OPEN': {
		return {
			...state,
			headerOpen: action.payload,
		}
	}
	default:{
		return state
	}
	}
}
