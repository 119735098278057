import style from './Legend.module.css'
import { useTranslation } from 'react-i18next'

export default props => {
	
	const { t } = useTranslation()
	const { map } = props
	const legendClasses = [style.masterlegend]
	if(map){
		legendClasses.push(style.mapLegend)
	}
	return (
		<div className={legendClasses.join(' ')}>
			<label>{t('legend_defendant')}</label>
			<div className={style.israelRow}>
				<div className={style.dot}></div> <div> {t('legend_israel')}</div>
			</div>
			<div className={style.palestinianRow}>
				<div className={style.dot}></div> <div> {t('legend_palestinian_authority')}</div>
			</div>
		</div>
	)
}