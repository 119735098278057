import { useEffect, useState, useReducer, createContext, useContext } from 'react'
import { BrowserRouter, Routes, Route, Outlet, useParams, useLocation, Link, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useBodyClass from './hooks/use-body-class'
import i18n from './i18n'

import dataLanguages from './data/data.json'
import { reducer } from './reducers'
import { setSliderValues, setFilter, resetFilters, setCurrentEntries, setCurrentEntry, setDetailsOpen, setHeaderOpen } from './actions'
import { getDateByPercentage, isValidDate, formatMonthDayDate } from './functions'

import Map from './components/Map'
import Slider from './components/Slider'
import Timeline from './components/Timeline'
import Filter from './components/Filter'
import Details from './components/Details'

import InfoAbout from './components/InfoAbout'
import InfoMethodology from './components/InfoMethodology'
import InfoCivilianHarm from './components/InfoCivilianHarm'
import InfoRightToRemedy from './components/InfoRightToRemedy'

import style from './App.module.css'
import './Typography.module.css'
import logo from './images/Ceasefir_logoWHITE.svg'
import searchIcon from './images/magnifying-glass-sharp-regular.svg'
import filterIcon from './images/filters-duotone.svg'

let dateStart, dateEnd
const dates = []

for (let lang in dataLanguages) {
	
	dataLanguages[lang].forEach(entry => {
		
		entry.incidentDate = undefined
		entry.decisionDate = undefined

		if ((entry.dateofincident+'').toLowerCase() !== 'unknown') {		
			const incidentDate = new Date(entry.incidentyear, entry.incidentmonth - 1, entry.incidentday)

			if (isValidDate(incidentDate)) {
				entry.incidentDate = incidentDate
				dates.push(incidentDate)				
			}

		}

		if ((entry.dateofincident+'').toLowerCase() !== 'unknown') {
			const decisionDate = new Date(entry.decisionyear, entry.decisionmonth - 1, entry.decisionday)
			if (isValidDate(decisionDate)) {
				entry.decisionDate = decisionDate
				dates.push(decisionDate)
			}
		}
		
	})
}

dates.sort(function(a, b) {
	return a - b
})

dateStart = dates[0]
dateEnd = dates[dates.length-1]


const searchFields = [
	'reference_short',
	'result',
	'costs',
	'compenstion',
	'judge_sname',
	'plaintiffs_nationality',
	'plaintiffs_ageattimeoftort',
	'plaintiffs_gender',
	'court',
	'causeofaction',
	'defendant_israel_palestinianauthority_other',
	'casesummary',
	'incidentDate',
	'decisionDate',
]


const AppContext = createContext()

const AppProvider = ({ children }) => {
	const [ state, dispatch ] = useReducer(reducer, { 
		sliderValues: [0, 100],
		currentFilters: {
			plaintiffs_nationality: undefined,
			result: undefined,
			typeofloss_death_injury_property: undefined,
			combatantactivitiesexceptionapplies_yes_no_notdiscussed: undefined,
			search: '',
		},
		currentEntries: [],
		currentEntry: undefined,
		detailsOpen: false,
	})
	return (
		<AppContext.Provider value={{ state, dispatch }}>
			{children}
		</AppContext.Provider>
	)
}

const useAppContext = () => {
	const context = useContext(AppContext)
	if (context === undefined) {
		throw new Error('useAppContext must be used within a AppProvider')
	}
	return context
}



export default props => {
	return (
		<BrowserRouter>
			<AppProvider>
				<ScrollToTop />
				<Routes>
					<Route path="/:lang/*" element={<Lang />}>
						<Route index element={<CeasefireMap />} />
						<Route path="about" element={<InfoAbout />} />
						<Route path="methodology" element={<InfoMethodology />} />
						<Route path="civilian-harm" element={<InfoCivilianHarm />} />
						<Route path="right-to-remedy" element={<InfoRightToRemedy />} />
					</Route>
					<Route path="/" element={<Lang />}>
						<Route index element={<CeasefireMap />} />
						<Route path="about" element={<InfoAbout />} />
						<Route path="methodology" element={<InfoMethodology />} />
						<Route path="civilian-harm" element={<InfoCivilianHarm />} />
						<Route path="right-to-remedy" element={<InfoRightToRemedy />} />
					</Route>
				</Routes>
			</AppProvider>
		</BrowserRouter>
	)
}

const ScrollToTop = props => {
	const { pathname } = useLocation()

	const { dispatch } = useAppContext()


	useEffect(() => {
		
		window.scrollTo(0, 0)
		document.getElementById('root').scrollTop = 0
		dispatch(setDetailsOpen(false))
		dispatch(setHeaderOpen(false))
	}, [pathname])

	return null
}


const Lang = props => {

	const { lang = 'en' } = useParams()
	useBodyClass()

	useEffect(() => {
		i18n.changeLanguage(lang)
		document.querySelector('html').setAttribute('lang', lang)
		if(lang != 'en'){
			document.querySelector('html').setAttribute('dir', 'rtl')
		} else {
			document.querySelector('html').setAttribute('dir', 'auto')
		}
	}, [lang, i18n])

	return (
		<div className={style.pageContainer}>
			<Header />
			<Outlet />
		</div>
	)
}

const Header = props => {
	
	const { t } = useTranslation()
	const params = useParams()

	const { state, dispatch } = useAppContext()
	const { headerOpen } = state

	const slug = params['*']

	const language = i18n.language
	const languages = [
		{ language: 'english', label: 'English', lang: 'en' },
		{ language: 'hebrew', label: 'עברית', lang: 'he' },
		{ language: 'arabic', label: 'العربية', lang: 'ar' },
	]
	const classNames = []
	
	if(headerOpen){
		classNames.push(style.headerOpen)
	}

	return (
		<header className={classNames.join(' ')}>
			<div className={style.headerInner}>
				
				<div className={style.logoSubtitle}>
					<div className={style.logo}><Link to={`/${language}`}><img src={logo}/></Link></div>
								
					<div className={style.subtitle}><Link to={`/${language}`}>{t('subtitle')}</Link></div>
				</div>
				
				<div className={style.nav}>
					
					<div className={style.navInner}>
						<div><NavLink end className={({ isActive }) => isActive ? style.activeLink : '' } to={`/${language}`}>{t('navigation_map')}</NavLink></div>
						<div><NavLink className={({ isActive }) => isActive ? style.activeLink : '' } to={`/${language}/about`}>{t('navigation_about')}</NavLink></div>
						<div><NavLink className={({ isActive }) => isActive ? style.activeLink : '' } to={`/${language}/methodology`}>{t('navigation_methodology')}</NavLink></div>
						<div><NavLink className={({ isActive }) => isActive ? style.activeLink : '' } to={`/${language}/civilian-harm`}>{t('navigation_civilian_harm')}</NavLink></div>
						<div><NavLink className={({ isActive }) => isActive ? style.activeLink : '' } to={`/${language}/right-to-remedy`}>{t('navigation_right_to_remedy')}</NavLink></div>
					</div>
				
				
					<div className={style.languages}>
						<ul className={style.languageList}>
							{languages.map(lang => {
								let className = ''
								if(lang.lang == language){
									className = style.activeLang
								}

								const href = (slug) ? `/${lang.lang}/${slug}` : `/${lang.lang}`

								return (
									<li className={className} key={`language_${lang.lang}`}>
										<Link to={href}>{lang.label}</Link>
									</li>
								)
							})}
						</ul>
					</div>
					
				</div>
				<div className={style.mobileToggle} onClick={e=>dispatch(setHeaderOpen(!headerOpen))}>{t('navigation_menu')}</div>
			</div>
		</header>
	)
}

const CeasefireMap = props => {

	const { t } = useTranslation()
	const language = i18n.language

	const [ data, setData ] = useState(null)
	const [filterOpen, setFilterOpen] = useState(false)
	
	const { state, dispatch } = useAppContext()

	const { sliderValues, currentFilters, currentEntries, currentEntry, detailsOpen } = state

	const entries = []

	useEffect(() => {
		dispatch(resetFilters())
		setData(dataLanguages[language])
		dispatch(setCurrentEntries(dataLanguages[language]))
	}, [language])

	if (!data) {
		return null
	}


	const sliderDateStart = getDateByPercentage(dateStart, dateEnd, sliderValues[0] * .01)
	const sliderDateEnd = getDateByPercentage(dateStart, dateEnd, sliderValues[1] * .01)


	data.forEach(entry => {

		let include = true

		if (currentFilters.plaintiffs_nationality && currentFilters.plaintiffs_nationality !== entry.plaintiffs_nationality) {
			include = false
		}

		if (currentFilters.result && currentFilters.result !== entry.result) {
			include = false
		}

		if (currentFilters.typeofloss_death_injury_property && currentFilters.typeofloss_death_injury_property !== entry.typeofloss_death_injury_property) {
			include = false
		}	

		if (currentFilters.combatantactivitiesexceptionapplies_yes_no_notdiscussed && currentFilters.combatantactivitiesexceptionapplies_yes_no_notdiscussed !== entry.combatantactivitiesexceptionapplies_yes_no_notdiscussed) {
			include = false
		}

		if (entry.incidentDate < sliderDateStart || entry.incidentDate > sliderDateEnd || entry.decisionDate < sliderDateStart || entry.decisionDate > sliderDateEnd) {
			include = false	
		}
	

		if (currentFilters.search) {
			let searchInclude = false
			searchFields.forEach(field => {

				let val = '' + entry[field]

				if (field === 'incidentDate' || field === 'decisionDate') {
					val = formatMonthDayDate(entry[field])
				}

				if (val.toLowerCase().includes(currentFilters.search.toLowerCase())) {
					searchInclude = true	
				}
			})

			if (!searchInclude) {
				include = false
			}

		}

		if (include) {
			entries.push(entry)
		}
	})	

	const detailEntries = (currentEntries.length > 0) ? currentEntries : entries



	let hasFilter = Object.values(currentFilters).some(value => value !== undefined) || sliderValues[0] !== 0 || sliderValues[1] !== 100

	const handleClearCurrentEntries = () => {
		if (currentEntries.length > 0) {
			dispatch(setCurrentEntries([]))
		}
	}

	const filterClasses = [style.container]
	if(filterOpen){
		filterClasses.push(style.filterOpen)
	}
	return (
		<div data-lang={language}  className={filterClasses.join(' ')}>
			<div className={style.filterContainer}>
				
				

				<div className={style.filterContainerInner}>
					<div className={style.filters}>
						<Filter 
							data={data} 
							prop="plaintiffs_nationality" 
							value={currentFilters.plaintiffs_nationality} 
							onChange={filter => {
								handleClearCurrentEntries()
								dispatch(setFilter(filter))
							}} 
							label={t('filter_plaintiff_nationality')}
						/>
						<Filter 
							data={data} 
							prop="result" 
							value={currentFilters.result} 
							onChange={filter => {
								handleClearCurrentEntries()
								dispatch(setFilter(filter))
							}}
							label={t('filter_result')}
						/>
						<Filter 
							data={data} 
							prop="typeofloss_death_injury_property" 
							value={currentFilters.typeofloss_death_injury_property} 
							onChange={filter => {
								handleClearCurrentEntries()
								dispatch(setFilter(filter))
							}}
							label={t('filter_type_of_loss')}
						/>
						<Filter 
							data={data} 
							prop="combatantactivitiesexceptionapplies_yes_no_notdiscussed" 
							value={currentFilters.combatantactivitiesexceptionapplies_yes_no_notdiscussed} 
							onChange={filter => {
								handleClearCurrentEntries()
								dispatch(setFilter(filter))
							}}
							label={t('filter_combatant_activities')}
						/>
	
						

						<div className={style.search}>
							<label>{t('filter_search')}</label>

							<div className={style.searchInput}>
								<div className={style.searchIcon}><img src={searchIcon}/></div>
								<input placeholder={t('filter_search_placeholder')} type="search" value={currentFilters.search || ''} onChange={e => {
									const search = (e.target.value) ? e.target.value : ''

									handleClearCurrentEntries()
									dispatch(setFilter({search}))
								}}/>
							</div>
						</div>
					</div>

					
					<Slider 
						entries={entries}
						data={data} 
						dateStart={dateStart}
						dateEnd={dateEnd}
						sliderValues={sliderValues} 
						currentEntry={currentEntry}
						onChange={values => {
							handleClearCurrentEntries()
							dispatch(setSliderValues(values))
						}} 
						
					/>
									
				</div>
			
				<div className={style.results}>
					<div className={style.filterToggle} onClick={e=>setFilterOpen(!filterOpen)}>
						{filterOpen ? (
							<>
								✕ {t('filter_close')}
							</>
						) : (
							<>
								<img src={filterIcon} alt='Close' />
								{t('filter_open')}
							</>
						)}
					</div>
					<div>
						{entries.length} {t('results')}
						{hasFilter && <button onClick={e => dispatch(resetFilters())}>Reset</button>}
					</div>

					{!detailsOpen && <div className={style.lastUpdated}>* {t('last_updated')}: {formatMonthDayDate('2024-11-06', language)}</div>}
				</div>
			</div>
			<div className={style.columns}>
				<div className={style.timeline}>
					<Timeline 
						entries={entries}
						data={data} 
						dateStart={dateStart}
						dateEnd={dateEnd}
						currentEntry={currentEntry}
						onBarClick={entry => {
							dispatch(setCurrentEntries([entry]))
							dispatch(setCurrentEntry(entry))
						}}
					/>
				</div>

				<main>
		
					<Map 
						sliderDateStart={sliderDateStart}
						sliderDateEnd={sliderDateEnd}
						lang={language}
						data={data} 
						entries={entries} 
						currentEntry={currentEntry}
						onMarkerClick={entries => {
							dispatch(setCurrentEntries(entries))

							if (entries.length > 0) {
								dispatch(setDetailsOpen(open))
							}
							// 
							if (entries.length === 1) {
								dispatch(setCurrentEntry(entries[0]))
							}
						}}
						onMapClick={handleClearCurrentEntries}
					/>
				</main>
			

				<Details 
					language={language}
					entries={detailEntries}
					search={currentFilters.search}
					currentEntry={currentEntry}
					onEntryClick={entry => (currentEntry && entry.id === currentEntry.id) ? dispatch(setCurrentEntry()) : dispatch(setCurrentEntry(entry))}
					detailsOpen={detailsOpen}
					onToggleOpen={open => dispatch(setDetailsOpen(open))}
				/>
			</div>
		</div>
	)	
}
