export const setSliderValues = payload => {
	return { 
		type: 'SET_SLIDER_VALUES', 
		payload: payload,
	}
}

export const setFilter = payload => {
	return { 
		type: 'SET_FILTER', 
		payload: payload,
	}
}

export const resetFilters = () => {
	return { 
		type: 'RESET_FILTERS', 
	}
}

export const setCurrentEntries = payload => {
	return { 
		type: 'SET_CURRENT_ENTRIES', 
		payload: payload,
	}
}

export const setCurrentEntry = payload => {
	return { 
		type: 'SET_CURRENT_ENTRY', 
		payload: payload,
	}
}

export const setDetailsOpen = payload => {
	return { 
		type: 'SET_DETAILS_OPEN', 
		payload: payload,
	}
}

export const setHeaderOpen = payload => {
	return { 
		type: 'SET_HEADER_OPEN', 
		payload: payload,
	}
}



