import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import Highlighter from 'react-highlight-words'

import style from './Details.module.css'
import {formatMonthDayDate} from '../functions/index'
import iconLeft from './../images/chevrons-left-solid.svg'
import iconRight from './../images/chevrons-right-solid.svg'


export default function Details(props) {

	const { entries, currentEntry, search, detailsOpen, language } = props
	const { t } = useTranslation()

	const searchWords = search ? search.split(' ') : []
	// const [detailsOpen, setDetailsOpen] = useState(false)
	const detailsClasses = [style.details]

	if(detailsOpen){
		detailsClasses.push(style.detailsOpen)
	}
	return (

		<div className={detailsClasses.join(' ')}>
			<div className={style.toggle} onClick={e=>props.onToggleOpen(!detailsOpen)}>
				{detailsOpen ? (					
					<img src={iconRight} />					
				) : (
					<img src={iconLeft} />
				)}
			</div>
			<div className={style.detailsTitle}><h4>{t('heading_civilian_harm_claim_details')}</h4></div>
			<div className={style.detailRows}>
				{entries.map(entry => {
					const active = (currentEntry && currentEntry.id === entry.id)
					const open = active || search
					const classNames = [style.detail]
					if(active){
						classNames.push(style.active)
					}

					const incidentDate = new Date(entry.incidentyear, entry.incidentmonth - 1, entry.incidentday)
					const decisionDate = new Date(entry.decisionyear, entry.decisionmonth - 1, entry.decisionday)

					return (
						<div key={`entries_list_${entry.id}`} className={classNames.join(' ')} onClick={e => props.onEntryClick(entry)}>
							<div className={style.reference}>
								<Highlighter
									highlightClassName="highlight"
									searchWords={searchWords}
									autoEscape={true}
									textToHighlight={entry.reference_short + ''}
								/>
							</div>

							<div>
								<label>{t('details_incident_date')}</label>
								<div>
									<Highlighter
										highlightClassName="highlight"
										searchWords={searchWords}
										autoEscape={true}
										textToHighlight={formatMonthDayDate(incidentDate, language) + ''}
									/>
								</div>
							</div>
							
							<div>
								<label>{t('details_decision_date')}</label>
								<div>
									<Highlighter
										highlightClassName="highlight"
										searchWords={searchWords}
										autoEscape={true}
										textToHighlight={formatMonthDayDate(decisionDate, language) + ''}
									/>
								</div>
							</div>
							<div className={style.result}>
								<label>{t('details_result')}</label>
								<div>
									<Highlighter
										highlightClassName="highlight"
										searchWords={searchWords}
										autoEscape={true}
										textToHighlight={entry.result + ''}
									/>
								</div>
							</div>

							<div className={style.arrow}></div>

							{(active || open) && (
								<Fragment>
									<div>
										<label>{t('details_costs')}</label>
										<div>
											<Highlighter
												highlightClassName="highlight"
												searchWords={searchWords}
												autoEscape={true}
												textToHighlight={entry.costs + ''}
											/>
										</div>
									</div>
									<div>
										<label>{t('details_compensation')}</label>
										<div>
											<Highlighter
												highlightClassName="highlight"
												searchWords={searchWords}
												autoEscape={true}
												textToHighlight={entry.compenstion + ''}
											/>
										</div>
									</div>

									<div>
										<label>{t('details_judge')}</label>
										<div>
											<Highlighter
												highlightClassName="highlight"
												searchWords={searchWords}
												autoEscape={true}
												textToHighlight={entry.judge_sname + ''}
											/>
										</div>
									</div>
									
									<div>
										<label>{t('details_plaintiff_nationality')}</label>
										<div>
											<Highlighter
												highlightClassName="highlight"
												searchWords={searchWords}
												autoEscape={true}
												textToHighlight={entry.plaintiffs_nationality + ''}
											/>
										</div>
									</div>
									<div>
										<label>{t('details_plaintiff_age')}</label>
										<div>
											<Highlighter
												highlightClassName="highlight"
												searchWords={searchWords}
												autoEscape={true}
												textToHighlight={entry.plaintiffs_ageattimeoftort + ''}
											/>
										</div>
									</div>
									<div>
										<label>{t('details_plaintiff_gender')}</label>
										<div>
											<Highlighter
												highlightClassName="highlight"
												searchWords={searchWords}
												autoEscape={true}
												textToHighlight={entry.plaintiffs_gender + ''}
											/>										
										</div>
									</div>

									<div>
										<label>{t('details_court')}</label>
										<div>
											<Highlighter
												highlightClassName="highlight"
												searchWords={searchWords}
												autoEscape={true}
												textToHighlight={entry.court + ''}
											/>
										</div>
									</div>
									
									<div>
										<label>{t('details_cause_of_action')}</label>
										<div>
											<Highlighter
												highlightClassName="highlight"
												searchWords={searchWords}
												autoEscape={true}
												textToHighlight={entry.causeofaction + ''}
											/>
										</div>
									</div>
									<div>
										<label>Defendant</label>
										<div>
											<Highlighter
												highlightClassName="highlight"
												searchWords={searchWords}
												autoEscape={true}
												textToHighlight={entry.defendant_israel_palestinianauthority_other + ''}
											/>
										</div>
									</div>
									
									<div className={style.casesummary}>
										<label>{t('details_case_summary')}</label>
										<div>
											<Highlighter
												highlightClassName="highlight"
												searchWords={searchWords}
												autoEscape={true}
												textToHighlight={entry.casesummary + ''}
											/>
										</div>
									</div>
								</Fragment>
							)}
						</div>	
					)
				})}
			</div>

		</div>


	)
}