import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import translationEN from '../locales/en.json'
import translationHE from '../locales/he.json'
import translationAR from '../locales/ar.json'

const resources = {
	en: {
		translation: translationEN
	},
	he: {
		translation: translationHE
	},
	ar: {
		translation: translationAR
	}
}

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
		keySeparator: false, // we do not use keys in form messages.welcome
		interpolation: {
			escapeValue: false // react already safes from xss
		}
	})

export default i18n