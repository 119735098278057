import { useTranslation } from 'react-i18next'
import style from './Filter.module.css'

export default function Filter(props) {

	const { t } = useTranslation()

	const { data, prop, value } = props
	
	const options = []
	data.forEach(entry => {
		const value = entry[prop]
		if (!options.includes(value)) {
			options.push(value)
		}
	})

	options.sort()

	const handleChange = e => {
		let value
		if (e.target.value) {
			const numValue = Number(e.target.value)
			if (!Number.isNaN(numValue)) {
				value = numValue
			} else  {
				value = e.target.value
			}
		}

		props.onChange({
			[prop]: value
		})
	}

	const classes = [style.filter]
	if (value) {
		classes.push(style.active)
	}

	return (
		<div className={classes.join(' ')}>
			<label>{props.label}</label>
			<select onChange={handleChange} value={value ? value : ''}>
				<option value="">{t('filter_select')}</option>
				{options.map(option => {
					return <option key={`option_${option}`} value={option}>{option}</option>
				})}
			</select>
			{value && (
				<button onClick={e => props.onChange({[prop]: ''})}>×</button>
			)}
		</div>
	)
}