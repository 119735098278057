export function getDateByPercentage(startDate, endDate, percentage) {
	const startTimestamp = startDate.getTime()
	const endTimestamp = endDate.getTime()
	
	const range = endTimestamp - startTimestamp
	const desiredTimestamp = startTimestamp + (range * percentage)
	
	const desiredDate = new Date(desiredTimestamp)
	return desiredDate
}

export function formatDate(date) {
	return new Intl.DateTimeFormat('en-US', { day: 'numeric', month: 'long', year: 'numeric' }).format(date)
}

export function formatMonthDayDate(date, language) {
	let languageCode = 'en-GB'
	if(language == 'ar'){
		languageCode = 'ar-EG'
	} else if(language == 'he'){
		languageCode = 'he-IL'
	}
	var options = { year: 'numeric', month: 'short', day: 'numeric'}
	var jsDate  = new Date(date)
	return jsDate.toLocaleDateString(languageCode, options)
}

export function formatYear(date) {
	return date.getFullYear()
}

export function isValidDate(date) {
	return !isNaN(date.getTime())
}