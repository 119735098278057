import { useTranslation } from 'react-i18next'
import style from './Page.module.css'
import image from './../images/ceasefire-1-flag.jpg'

export default props => {
	const { i18n } = useTranslation()
	const language = i18n.language

	switch(language) {
	case 'en':
		return <RightToRemedyEnglish />
	case 'he':
		return <RightToRemedyHebrew />
	case 'ar':
		return <RightToRemedyArabic />
	default:
		return <RightToRemedyEnglish />
	}
}

const RightToRemedyEnglish = props => {
	return (

		<div className={style.page}>
			<div className={style.hero}>
				<div className={style.heroimage}><img src={image}/></div>
				<div className={style.heroblur}><img src={image}/></div>
			</div>
			<div className={style.pageInner}>
				<div className={style.imageCaption}></div>
				<div className={style.pageText}>
					
					<h1>The right to a remedy and reparation</h1>
					<p>The Universal Declaration of Human Rights grounds the right to an effective remedy by the competent national authorities for violations of fundamental rights granted by the constitution or by law (Article 8), as well as the prohibition on discrimination (Article 7). Equal and effective access to justice that offers adequate and prompt reparation for harms suffered as well as access to relevant information concerning violations and reparation mechanisms are key.</p>
					<p>As a matter of treaty law, Israel is party to most of the core international human rights treaties and is bound by their provisions on the right to a remedy and reparation, including Article 2 of the International Covenant on Civil and Political Rights (ICCPR), and Article 6 of the International Convention on the Elimination of All Forms of Racial Discrimination (ICERD). Israel is also bound by international humanitarian law, including the four Geneva Conventions of 1949. The <a href="https://www.ohchr.org/en/instruments-mechanisms/instruments/basic-principles-and-guidelines-right-remedy-and-reparation" target="_blank">UN Basic Principles and Guidelines on the Right to a Remedy and Reparation for Victims of Gross Violations of Human Rights and Serious Violations of International Humanitarian Law</a> codify standards for the implementation of obligations under the right to a remedy and reparation, including equal and effective access to justice.</p>
					<p>The data on civilian harm claims before the Israeli courts reveal that Israel falls short of its obligations in this context. That said, Israel is not alone in this shortfall. Australia, Canada, the United Kingdom and the United States all extend some form of an immunity from tort liability to the State for losses it inflicts during combat. The scope of these immunities vary, but they all hinder civilians’ ability to claim remedies as a matter of right.</p>
				
				</div>
				<div className={style.imageCaption}>© Dominika Zara/Shutterstock</div>
			</div>
		</div>
	)
}

const RightToRemedyHebrew = props => {
	return (
		<div className={style.page}>
			<div className={style.hero}>
				<div className={style.heroimage}><img src={image}/></div>
				<div className={style.heroblur}><img src={image}/></div>
			</div>
			<div className={style.pageInner}>
				<div className={style.imageCaption}></div>
				<div className={style.pageText}>
					<h1>הזכות לסעד ופיצויים</h1>
					<p>הכרזת האומות המאוחדות בדבר זכויות אדם מעגנת את הזכות לתרופה יעילה על ידי רשות ציבורית מוסמכת על הפרות של זכויות אדם המעוגנות בחוקה או בחקיקה (סעיף 8) ואוסרת על הפליה (סעיף 7). גישה שיוונית ויעילה לערכאות המציעה פיצויים הולמים בזמן סביר לנזקים, וכן גישה למידע על ההפרות ומנגנוני הפיצויים, הם חלק אינטגרלי מדרישות אלו.</p>
					<p>ישראל הינה צד למרבית מאמנות זכויות האדם הבינלאומית המכירות בזכות לתרופה ופיצויים, ובכלל זאת סעיף 2 של האמנה בדבר זכויות אזרחיות ופוליטיות וסעיף 6 של האמנה לביטול כל צורות האפליה הגזעית. ישראל מחויבת למשפט הבינלאומי ההומניטרי, ובכלל זאת לאמנות ג'נבה. הסטנדרטים הנדרשים בכדי לעמוד בהתחייבויות אלו קודדו <a href="https://www.ohchr.org/en/instruments-mechanisms/instruments/basic-principles-and-guidelines-right-remedy-and-reparation" target="_blank">במסמך הקווים המנחים</a> של האו"ם.</p>
					<p>הנתונים שאספנו מצביעים על כך שישראל לא עומדת בהתחייבויותיה במישור הבינלאומי. עם זאת, ישראל אינה המדינה היחידה שכושלת בהקשר זה. אוסטרליה, אנגליה, ארצות הברית וקנדה מחילות גרסאות שונות לחריג הפעולה המלחמתית לתביעות נזיקין המקנות אף הן חסינות מפני תביעות אלו למדינה בגין נזקים שגרמו כוחותיה במהלך סכסוכים מזוינים. היקף החריגים משתנה, אבל כולם מונעים מאזרחים את האפשרות לתבוע פיצויים כזכות.</p>
				</div>
				<div className={style.imageCaption}>© Dominika Zara/Shutterstock</div>
			</div>
		</div>
	)
}

const RightToRemedyArabic = props => {
	return (
		<div className={style.page}>
			<div className={style.hero}>
				<div className={style.heroimage}><img src={image}/></div>
				<div className={style.heroblur}><img src={image}/></div>
			</div>
			<div className={style.pageInner}>
				<div className={style.imageCaption}></div>
				<div className={style.pageText}>
					<h1>الحق في سبل الانتصاف وجبر الضرر</h1>
					<p>على أسس من الإعلان العالمي لحقوق الإنسان، يقوم الحق في الحصول على سبل انتصاف فعالة من قِبَل السلطات الوطنية المختصة عن انتهاكات الحقوق الأساسية التي يكفلها الدستور أو القانون (المادة 8)، بالإضافة لحظر التمييز (المادة 7). والوصول المتساوي والفعال للعدالة التي تقدم جبرًا ملائمًا وسريعًا للضرر والخسائر المتكبدة وكذلك فإن الوصول للمعلومات المناسبة التي تتعلق بالانتهاكات وآليات جبر الضرر هي أمور أساسية.</p>
					<p>كمسألة تتعلق بقوانين المعاهدات، فإن إسرائيل طرف في معظم المعاهدات الدولية الأساسية الخاصة بحقوق الإنسان وهي ملزمة بأحكامها بشأن الحق في سبل الانتصاف وعمليات جبر الضرر، ومن بين ذلك المادة 2 من العهد الدولي الخاص بالحقوق المدنية والسياسية، والمادة 6 من الاتفاقية الدولية للقضاء على جميع أشكال التمييز العنصري. وإسرائيل ملزمة أيضًا بأحكام القانون الإنساني الدولي، بما في ذلك اتفاقيات جنيف الأربعة لعام 1949. إن مبادئ <a href="https://www.ohchr.org/en/instruments-mechanisms/instruments/basic-principles-and-guidelines-right-remedy-and-reparation" target="_blank">الأمم المتحدة الأساسية ومبادئها التوجيهية بشأن الحق في الانتصاف والجبر لضحايا الانتهاكات الجسيمة للقانون الإنساني الدولي</a> تقنن المعايير المطلوبة لتنفيذ الالتزامات الواقعة بموجب الحق في الانتصاف والجبر، بما في ذلك الوصول المتساوي والفعال للعدالة.</p>
					<p>تكشف البيانات المتعلقة بالدعاوى القضائية للمطالبة بالتعويضات عن الأضرار التي تلحق بالمدنيين المرفوعة أمام المحاكم الإسرائيلية أن إسرائيل مقصرة عن الإيفاء بالتزاماتها في هذا السياق. ومع ذلك، فإن إسرائيل ليست وحدها المقصرة في هذا الأمر. أستراليا، وكندا، والمملكة المتحدة والولايات المتحدة الأمريكية جميعهم يوسِّعون شكل من أشكال الحصانة من المسؤولية التقصيرية للدولة عن الأضرار والخسائر إن حدثت أثناء المعارك. يختلف نطاق أشكال الحصانة هذه، لكن جميعها تعيق قدرة المدنيين على رفع الدعاوى القضائية للانتصاف لأنفسهم كحق من حقوقهم.</p>
				</div>
				<div className={style.imageCaption}>© Dominika Zara/Shutterstock</div>
			</div>
		</div>
	)
}
