import React, { useState, useEffect } from 'react'
import ReactSlider from 'react-slider'
import { useTranslation } from 'react-i18next'
import { getDateByPercentage, formatYear } from '../functions'
import style from './Slider.module.css'
import play from '../images/play-sharp-solid.svg'
import pause from '../images/pause-sharp-solid.svg'

export default function Slider(props) {
	// const [values, setValues] = useState([0, 100])
	const [ isPlaying, setIsPlaying ] = useState(false)
	const [ shouldResume, setShouldResume ] = useState(false)
	const { sliderValues, dateStart, dateEnd } = props
	const { t } = useTranslation()

	useEffect(() => {
		let interval = null
		if (isPlaying) {
			let nextValues = [sliderValues[0], sliderValues[1], sliderValues[sliderValues.length-1]]
			props.onChange(nextValues)
			interval = setInterval(() => {
				if (nextValues[1] < nextValues[2]) {
					nextValues[1] += 1
				} else {
					nextValues[1] = nextValues[0]
				}
				props.onChange(nextValues)
			}, 100)
		} else if (!isPlaying) {
			props.onChange([sliderValues[0], sliderValues[sliderValues.length-1]])
			clearInterval(interval)
		}
		return () => clearInterval(interval)
	}, [isPlaying])

	return (
		<div className={style.sliderFlexContainer}>
			<div className={style.sliderContainer}>
				<div className={style.play}>
					<button onClick={() => setIsPlaying(!isPlaying)}>
						{isPlaying ? <span><img src={pause}/> {t('filter_pause')}</span> : <span><img src={play}/> {t('filter_play')}</span>}
					</button>
				</div>	
				<div>
					<div className={style.legend}>
						<div className={style.thumb}>1955</div>
						<div className={style.thumb}>2024</div>
					</div>
					<ReactSlider
						
						thumbClassName={style.thumb}
						trackClassName={style.track}
						value={sliderValues}
						onBeforeChange={(value, index) => {
							setShouldResume(isPlaying)
							setIsPlaying(false)

						}}
						onChange={newValues => {
							props.onChange(newValues)
						}}
						onAfterChange={newValues => {
							props.onChange(newValues)
							if (shouldResume) {
								setIsPlaying(true)
							}
						}}
						ariaLabel={(isPlaying) ? ['Leftmost thumb', 'Middle thumb', 'Rightmost thumb'] : ['Leftmost thumb', 'Rightmost thumb']}
						renderThumb={(props, state) => {
							return <div {...props}>{formatYear(getDateByPercentage(dateStart, dateEnd, state.valueNow * .01))}</div>}
						}
						pearling
						minDistance={0}

					/>
				</div>
				
			</div>
		</div>
	)
}



