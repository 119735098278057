import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default () => {
	const location = useLocation()

	useEffect(() => {
		const slug = location.pathname.replace(/\/en|\/he|\/ar|\//gi, '')
		document.body.dataset.page = slug || 'map'
	}, [location])
}
