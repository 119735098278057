import style from './Timeline.module.css'
import { useTranslation } from 'react-i18next'
import Legend from './Legend'

/*
<Timeline 
	entries={props.entries}
	data={data} 
	dateStart={dateStart} 
	dateEnd={dateEnd} 
	onBarClick={props.onBarClick} 
	currentEntry={currentEntry}
/>
*/			

export default props => {
	
	const { t } = useTranslation()

	const { data, dateStart, dateEnd, entries, currentEntry } = props

	const startYear = dateStart.getFullYear()
	//	const startYear = 1970
	const endYear = dateEnd.getFullYear()
	
	const years = []
	for (let year = startYear; year <= endYear; year++) {
		years.push(year)
	}

	const refWidth = 100 / (endYear - startYear) 

	return (
		<div className={style.timelineContainer}>
			<div className={style.timelineTitle}><h4>{t('heading_timeline_of_claims')}</h4></div>
			<div className={style.timeline}>
				<div className={style.timelineReference}>
					{years.map((year, yidx)=>{
						return <div key={`reference_${yidx}`} style={{width: refWidth+'%'}}></div>
					})}
				</div>
				<div className={style.timeline}>
					{data.map(entry => {

						if (entry.incidentDate && entry.decisionmonth) {
							const incidentYear = entry.incidentDate.getFullYear()
							const decisionYear = entry.decisionDate.getFullYear()
							
							const startPercentage = ((incidentYear - startYear) / (endYear - startYear)) * 100
							const width = ((decisionYear - incidentYear) / (endYear - startYear)) * 100
							const barClasses = [style.bar]
							
							if(currentEntry && currentEntry.id === entry.id){
								barClasses.push(style.barActive)	
							}

							if(entries.indexOf(entry) === -1){
								barClasses.push(style.inactive)
							}

							let defendantClass = style.israelRow
							if(entry.is_palestinian_authority){
								defendantClass = style.palestinianRow
							}

							if (!incidentYear || !decisionYear || incidentYear > decisionYear || incidentYear < startYear) {
								barClasses.push(style.invalid)
								return (
									<div className={[barClasses.join(' ')]} key={`bar_${entry.id}`} onClick={e => props.onBarClick(entry)}>
										<div className={[style.barInner, defendantClass].join(' ')} style={{left: '0%', width: '20px'}}></div>
										{/*{incidentYear} - {decisionYear}*/}
									</div>
								)
							}

							
							return (
								<div className={[barClasses.join(' ')]} key={`bar_${entry.id}`} onClick={e => props.onBarClick(entry)}>
									<div className={[style.barInner, defendantClass].join(' ')} style={{left: startPercentage+'%', width: width+'%'}}></div>
									{/*{incidentYear} - {decisionYear}*/}
								</div>
							)
						}
					})}
				</div>
			</div>
			<div className={style.legend}>
				<div>{startYear}</div>
				<div>{endYear}</div>
			</div>

			<Legend />
		</div>
	)
}

