import { useTranslation } from 'react-i18next'
import style from './Page.module.css'
import image from './../images/ceasefire-2-throw.jpg'

export default props => {
	const { i18n } = useTranslation()
	const language = i18n.language

	switch(language) {
	case 'en':
		return <MethodologyEnglish />
	case 'he':
		return <MethodologyHebrew />
	case 'ar':
		return <MethodologyArabic />
	default:
		return <MethodologyEnglish />
	}
}

const MethodologyEnglish = props => {
	return (
		<div className={[style.page, style.pageHero].join(' ')}>
			<div className={style.hero}>
				<div className={style.heroimage}><img src={image}/></div>
				<div className={style.heroblur}><img src={image}/></div>
			</div>
			<div className={style.pageInner}>
				<div className={style.imageCaption}></div>
				<div className={style.pageText}>
					<h1>Methodology and further resources</h1>
					<p>To create the database for the map, we reviewed and coded over 480 Israeli court cases available in Israel’s leading legal databases (Nevo and Takdin), as well as cases that are not publicly available online. At the first stage we conducted several Boolean searches, focusing on cases citing the ‘combatant activities exception’ as well as keywords such as ‘combat’, ‘liability’ and ‘tort’ that were mentioned in the same paragraph. A total of 454 decisions in which a loss was inflicted during combatant activities were found and coded. An additional Boolean search focusing on keywords such as ‘liability’ and ‘tort’ and the Palestinian Authority as a defendant yielded 32 decisions. Cases were coded to enable analysis of the nature of civilian harm claims over time, as well as the practice of the Israeli court and the application of Israel’s ‘combatant activities exception’.</p>
					<p>It should be noted that the database is comprised of judgments and orders. It does not include claims which settled outside the court doors, unless a judicial decision was given to that effect. According to a report provided in response to a freedom of information act (FOIA) query to the Israeli Ministry of Defense and independent research references below, many claims submitted during the years covered by the database were in fact settled. For example, based on Ministry of Defense data for the years 2007-2009, a total of 151 claims were settled outside the court, which accounted for thirty percent of the claims filed in those years. Due to lack of publicly available data regarding out-of-court settlements, there are not included in the database.</p>
					<p>For convenience and accessibility, the database is visualized by using OpenStreetMap. However, this use does not endorse nor advocate for a particular view of the international borders in the Israel/ Palestine region.</p>
					<h3>Related Resources:</h3>
					<RelatedResources />
				</div>
				<div className={style.imageCaption}>© Dominika Zara/Shutterstock</div>
			</div>
		</div>
	)
}

const MethodologyHebrew = props => {
	return (
		<div className={style.page}>
			<div className={style.hero}>
				<div className={style.heroimage}><img src={image}/></div>
				<div className={style.heroblur}><img src={image}/></div>
			</div>
			<div className={style.pageInner}>
				<div className={style.imageCaption}></div>
				<div className={style.pageText}>
					<h1>מתודולוגיה ומקורות נוספים</h1>
					<p>בכדי ליצור את המאגר עבור המפה בחנו וקודדנו יותר מ-480 החלטות של בתי משפט ישראליים הזמינות במאגרי המידע המשפטיים המובילים (נבו ותקדין), וכן תיקים שאינם זמינים דרכם באמצעות בקשות לפי חוק חופש המידע. בשלב הראשון, ערכנו חיפושים תוך התמקדות בתיקים שמצטטים את ‘חריג הפעולה המלחמתית’ ומילות מפתח כגון ‘לחימה’, ‘אחריות’, ו-‘נזיקין’ שהוזכרו באותה פסקה. 454 החלטות רלוונטיות נמצאו וקודדו. חיפוש נוסף נערך באמצעות מילות מפתח כגון ‘אחריות’ ו-‘נזיקין’ כשהרשות הפלסטינית היתה הנתבעת ו-32 החלטות נוספות נמצאו. התיקים קודדו כדי לאפשר ניתוח של אופי תביעות נזיקין של אזרחים לאורך זמן לצד יחס בתי המשפט לתביעות מסוג זה ולחריג הפעולה המלחמתית.</p>
					<p>יש לציין כי המאגר אינו כולל תביעות בהן הצדדים התפשרו מחוץ לכותלי בית המשפט אלא אם ישנה החלטה שיפוטית שנותנת תוקף להסכם הפשרה. לפי דוח שניתן בתגובה לבקשת חופש מידע למשרד הביטחון ומידע שנמצא במקורות הנוספים המצויינים למטה, תביעות רבות שהוגשו נגד המדינה הסתיימו בפשרה. לדוגמא, לפי מידע ממשרד הביטחון, בין השנים 2009-2007, 151 תביעות נסגרו בפשרה – 30 אחוזים מסך כל התביעות שהוגשו נגד המשרד. בשל היעדר מידע הזמין לציבור על פשרות אלו הן אינן כלולות במאגר.</p>
					<p>העיצוב החזותי של המאגר מבוסס על המפה של OpenStreetMap. שימוש זה נועד לנוחות והנגשה ואין לראות בשימוש בו כאימוץ או תמיכה בעמדות שונות כלפי הגבולות הבינלאומיים בין ישראל ופלסטין.</p>
					<h3>מקורות נוספים:</h3>
					<RelatedResources />
				</div>
				<div className={style.imageCaption}>© Dominika Zara/Shutterstock</div>
			</div>
		</div>
	)
}

const MethodologyArabic = props => {
	return (
		<div className={style.page}>
			<div className={style.hero}>
				<div className={style.heroimage}><img src={image}/></div>
				<div className={style.heroblur}><img src={image}/></div>
			</div>
			<div className={style.pageInner}>
				<div className={style.imageCaption}></div>
				<div className={style.pageText}>
					<h1>المنهجية ومصادر أخرى</h1>
					<p>لإنشاء قاعدة بيانات الخريطة، راجعنا وقمنا بتكويد ما يزيد عن 480 دعوى قضائية إسرائيلية في قواعد البيانات القانونية الرئيسية (نيفو وتاكدين)، بالإضافة للدعاوى القضائية الغير متاحة للجمهور على الإنترنت. في المرحلة الأولى، أجرينا عدة بحوث منطقية تركز على دعاوى قضائية تذكر عبارة ‘استثناء الأنشطة القتالية’ بالإضافة إلى كلمات مفتاحية مثل ‘قتال’، و‘مسؤولية’، و‘تقصيرية’ مذكورة معًا في نفس الفقرة. تم العثور إجمالًا على 454 قرار حيث نجمت أضرار أثناء وقوع أنشطة قتالية وتكويدها. وأسفر بحث منطقي آخر يركز على كلمات مفتاحية مثل ‘مسؤولية’ و‘تقصيرية’ والسلطة الفلسطينية باعتبارها الجهة المدعى عليها عن 32 قرار.</p>
					<p>تم تكويد الدعاوى القضائية لإتاحة إجراء تحليل لتطور طبيعة الدعاوى القضائية للمطالبة بالتعويض عن الأضرار مع مرور الوقت، بالإضافة إلى تطور ممارسات المحاكم الإسرائيلية مع مرور الزمن وكيفية تطبيق سريان ‘استثناء الأنشطة القتالية’ في إسرائيل.</p>
					<p>يجدر التنويه إلى أن قاعدة البيانات لا تتضمن الدعاوى القضائية التي تمت تسويتها خارج المحاكم، ما لم يصدر حكم قضائي من أجل هذا الغرض. وفقًا لتقرير مقدَّم ردًا على سؤال يتعلق بقانون حرية المعلومات تم توجيهه إلى وزارة الدفاع الإسرائيلية ووفقًا لمصادر البحث المستقلة الموضحة أدناه، تمت تسوية العديد من الدعاوى القضائية المقدمة على مر السنين والتي شملتها قاعدة البيانات. فعلى سبيل المثال، بناءً على بيانات وزارة الدفاع للسنوات من 2007 إلى 2009، تمت تسوية 151 دعوى قضائية خارج المحاكم والتي تمثل ثلاثين بالمائة من الدعاوى القضائية المرفوعة في تلك السنوات. نتيجة لعدم توفر البيانات المتاحة للجمهور حول تسويات الدعاوى خارج المحاكم، فإنها ليست متضمنة في قاعدة البيانات.</p>
					<p>للتسهيل وتيسير إمكانية الوصول، تم إعداد تصميم مصور للتعبير عن قاعدة البيانات باستخدام OpenStreetMap. وعلى الرغم من هذا، هذا الاستخدام لا يوافق أو يدعو لتصور بعينه للحدود الدولية في المنطقة الإسرائيلية / الفلسطينية.</p>
					<h3 dir="ltr">Related Resources:</h3>
					<RelatedResources />
				</div>
				<div className={style.imageCaption}>© Dominika Zara/Shutterstock</div>
			</div>
		</div>
	)
}

const RelatedResources = props => {
	return (
		<ul dir="ltr">
			<li><a href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3183321" target="_blank">Access Denied – Using Procedure to Restrict Tort Litigation: the Israeli-Palestinian Experience.</a></li>
			<li><a href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3185002" target="_blank">Collateral Damages: Monetary Compensation for Civilians in Asymmetric Conflict.</a></li>
			<li><a href="https://www.justsecurity.org/60803/discrimination-fight-terror" target="_blank">Discrimination in the Fight Against Terror: Israeli Court Applies Jewish Nation-State Law.</a></li>
			<li><a href="https://theconversation.com/israel-supreme-courts-double-standard-on-liability-is-unfair-to-palestinians-181969" target="_blank">Israel: Supreme Court’s Double Standard on Liability is Unfair to Palestinians.</a></li>
			<li><a href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3184997" target="_blank">Money for Justice: Plaintiffs’ Lawyers and Social Justice Tort Litigation.</a></li>
			<li><a href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3184992" target="_blank">The Occupation of the Law: Judiciary-Legislature Power Dynamics in Palestinians’ Tort Claims against Israel.</a></li>
			<li><a href="https://www.ejiltalk.org/the-war-on-compensation-troubling-signs-for-civilian-casualties-in-the-gaza-strip" target="_blank">The War on Compensation: Troubling Signs for Civilian Casualties in the Gaza Strip.</a></li>
			<li><a href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4000631" target="_blank">Tort Liability, Combatant Activities, and the Question of Over-Deterrence.</a></li>
			<li><a href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3458944" target="_blank">Tort Liability for Belligerent Wrongs.</a></li>
			<li><a href="https://slate.com/news-and-politics/2024/05/palestinian-reparations-rafah-fire-gaza-airstrike-israel.html" target="_blank">Are Reparations for Palestinians Possible?</a></li>
		</ul>
	)
}



