import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import 'mapbox-gl/dist/mapbox-gl.css'
import ReactMapGL, { Source, Layer, NavigationControl } from 'react-map-gl'
import style from './Map.module.css'

import mapboxgl from 'mapbox-gl'
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default
import Legend from './Legend'

export default function(props) {
	
	const { t } = useTranslation()

	const { entries, sliderDateStart, sliderDateEnd } = props
	const [hoverId, setHoverId] = useState(null)
	const [cursor, setCursor] = useState('auto')
	const [viewport, setViewport] = useState({
		latitude: 32,
		longitude: 34.8,
		zoom: 7.3
	})
	const accuracies = []
	const geojson = {
		type: 'FeatureCollection',
		features: []
	}

	// useEffect(() => {
	// 	console.log(currentEntry)
	// }, [currentEntry])

	entries.map((entry, index) => {
		if(accuracies.indexOf(entry.accuracyoflocation) === -1){
			accuracies.push(entry.accuracyoflocation)
		}	
		// console.log(entry.is_palestinian_authority)
		// if(entry.is_palestinian_authority == undefined){
		// 	console.log(entry)
		// }
		geojson.features.push({			
			'type': 'Feature',
			'geometry': {
				'type': 'Point',
				'coordinates': [entry.longitude, entry.latitude]						
			},
			'id': index, 
			'properties': entry,
		})
	})

	const color2 = getComputedStyle(document.documentElement).getPropertyValue('--color2')
	const color = getComputedStyle(document.documentElement).getPropertyValue('--color')

	const layerStyle = {
		id: 'clusters',
		type: 'circle',
		filter: ['has', 'point_count'],
		paint: {
			'circle-radius': [
				'interpolate',
				['linear'],
				['get', 'point_count'],
				2, 7,
				10, 15,
				50, 20
			],
			// 'circle-color': [
			// 	'match',
			// 	['get', 'has_palestine'],
			// 	'Israel', color,
			// 	color2
			// ],
			// 'circle-color': [
			// 	'case',
			// 	['==', ['get', 'has_palestine'], true],
			// 	'#806C91',
			// 	color
			// ],
			// 'circle-color': [
			// 	'case',
			// 	['get', 'only_palestine'],
			// 	color2,
			// 	['get', 'has_palestine'], 
			// 	'#806C91',				
			// 	color
			// ],
			'circle-color': [
				'interpolate',
				['linear'],
				['/', ['get', 'palestinianAuthorityCount'], ['get', 'point_count']],
				0, color,
				1, color2
			]

		}
	}
	const clusterLabelLayerStyle = {
		id: 'cluster-labels',
		type: 'symbol',
		filter: ['has', 'point_count'],
		paint: {
			'text-color': '#fff'
		},
		layout: {
			'text-field': '{point_count}',
			'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
			'text-size': 11,
			'text-allow-overlap': true
		}
	}

	const singlePointsStyle = {
		id: 'single-point',
		type: 'circle',
		filter: ['!has', 'point_count'],
		paint: {
			'circle-radius': [
				'interpolate',
				['linear'],
				['zoom'],
				8, 4,   // When zoom is 0, set circle radius to 20
				15, 7,  // When zoom is 10, set circle radius to 30
			],

			//'circle-color': color,
			// 'circle-color': [
			// 	'case',
			// 	['==', ['{defendant_israel_palestinianauthority_other}'], 'Israel'],
			// 	'#00ff00', // Change this to the color you want when hovered over
			// 	color // Change this to the default color
			// ],
			'circle-color': [
				'case',
				['get', 'is_palestinian_authority'], color2,
				color
			],
			'circle-opacity': [
				'case',
				['==', ['id'], hoverId],
				0.6,
				1 
			]
		}
	}

	const handleFeatureSelection = selectedFeatures => {
		const selectedEntries = []
		if (selectedFeatures) {
			selectedFeatures.forEach(selectedFeature => {
				const entry = entries.find(e => e.id === selectedFeature.properties.id)
				selectedEntries.push(entry)
			})
		}
		props.onMarkerClick(selectedEntries)	
	}

	const onMouseEnter = useCallback((e) => {
		setCursor('pointer')
		
		if (e.features.length > 0) {

			if (e.features[0].layer.id === 'single-point') {
				setHoverId(e.features[0].id)
			}
		} else {
			setHoverId(null)
		}

	}, [])

	const onMouseLeave = useCallback(() => {
		setCursor('auto')
		setHoverId(null)
	}, [])
	const clusterProperties = {
		// 'max': ['max', 0, ['get', 'scalerank']],
		// 'sum': ['+', 0, ['get', 'scalerank']],
		// has_palestine: ['any', ['==', ['get', 'defendant_israel_palestinianauthority_other'], 'Palestinian Authority'], 'false'],
		// only_palestine: ['all', ['==', ['get', 'defendant_israel_palestinianauthority_other'], 'Palestinian Authority'], 'false'],
		palestinianAuthorityCount: ['+', ['case', ['==', ['get', 'is_palestinian_authority'], true], 1, 0]]

	}
	
	return (
		<div className={style.map}>
			<div className={style.date}>
				<div className={style.detailsTitle}><h4>{t('heading_clustered_map_of_claims')}</h4></div>
				<div className={style.dateValue}>{sliderDateStart.getFullYear()} &mdash; {sliderDateEnd.getFullYear()}</div>
			</div>

			<ReactMapGL
				initialViewState={viewport}
				width="100%"
				height="100%"
				minZoom={5}
				maxZoom={17}
				cursor={cursor}
				onViewportChange={nextViewport => setViewport(nextViewport)}
				mapStyle={'mapbox://styles/anecdote101/cli42rsbz008c01r12ue8bmfw'}
				mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
				interactiveLayerIds={['clusters', 'single-point']}
				onClick={e => {

					if(e.features && e.features.length > 0){
						if(e.features[0].layer.id === 'clusters'){
							const features = e.target.queryRenderedFeatures(e.point, { layers: ['clusters'] })
							const clusterId = features[0].properties.cluster_id
							const pointCount = features[0].properties.point_count

							e.target.getSource('markers-source').getClusterLeaves(clusterId, pointCount, 0, (error, clusterLeavesFeatures) => {
								handleFeatureSelection(clusterLeavesFeatures)
							})
						} else {
							handleFeatureSelection([e.target.queryRenderedFeatures(e.point, { layers: ['single-point'] })[0]])
						}
					} else {
						handleFeatureSelection()
					}
				}}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}

			>


				<Source id="markers-source" clusterRadius={10} cluster={true} clusterProperties={clusterProperties} type="geojson" data={geojson}>
					<Layer {...layerStyle} />
					<Layer {...clusterLabelLayerStyle} />
					<Layer {...singlePointsStyle} />
				</Source>


				<NavigationControl showCompass={false} style={{filter: 'invert(1)'}} position={'bottom-right'} />

			</ReactMapGL>
			<Legend map={true} />
		</div>
	)
}
