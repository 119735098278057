import { useTranslation } from 'react-i18next'
import style from './Page.module.css'

import ucl from './../images/ucl.png'
import ucllaws from './../images/ucl-laws.jpg'
import ukri from './../images/ukri.png'
import minerva from './../images/minerva.jpg'
import image from './../images/ceasefire-3-barb.jpg'

export default props => {
	const { i18n } = useTranslation()
	const language = i18n.language

	switch(language) {
	case 'en':
		return <AboutEnglish />
	case 'he':
		return <AboutHebrew />
	case 'ar':
		return <AboutArabic />
	default:
		return <AboutEnglish />
	}
}

const AboutEnglish = props => {
	return (
		<div className={[style.page, style.pageHero].join(' ')}>
			<div className={style.hero}>
				<div className={style.heroimage}><img src={image}/></div>
				<div className={style.heroblur}><img src={image}/></div>
			</div>


			<div className={style.pageInner}>
				<div className={style.imageCaption}></div>
				<div className={style.pageText}>
					<h1>About</h1>
					<p>This interactive map presents over 450 Israeli court cases in which civilians sought compensation under tort law for loss of life, bodily injury, and property damage inflicted during armed activities. The vast majority of cases involve Palestinian civilians who were injured by Israeli security forces in the Gaza Strip and the West Bank. However, the database includes claims of other nationals, as well as cases in which Israeli civilians sought compensation against Hamas and the Palestinian Authority for losses incurred during hostilities.</p>
					<p>Databases that document the losses suffered by civilians in armed conflict are highly important to the facilitation of reparations and reconciliation. Telling individual stories gives voice to vulnerable populations, and aggregating these accounts in a single resource allows the scope and character of the loss that armed conflict inflicts on a community to become apparent. Having a clear account of the wrongs committed during conflict is key to holding those responsible accountable, and accountability and reparations are cardinal to reconciliation between those who suffered the wrongful loss and those who inflicted it. </p>
					<p>In the context of reparation for losses civilians sustain during armed conflict and occupation, Israel offers a unique perspective. The number of cases in which Israeli courts examine the question of state liability in tort law for losses the Israeli military is alleged to have inflicted exceeds the combined cases of Australia, Canada, the United Kingdom and the United States. The Israeli cases offer a unique insight into legal institutional approaches to urban warfare and counter-terrorism, as well as to attitudes towards prolonged conflict over time. </p>
					<p>This project is a collaboration between Dr <a href="https://www.ucl.ac.uk/laws/people/haim-abraham" target="_blank">Haim Abraham</a> (UCL Faculty of Laws), Prof. <a href="https://law.temple.edu/contact/gilat-juli-bachar/" target="_blank">Gilat Bachar</a> (Temple University Beasley School of Law), and <a href="https://www.ceasefire.org/" target="_blank">Ceasefire Centre for Civilian Rights</a>. We were assisted by Mr. <a href="https://www.ucl.ac.uk/bartlett/development/people/matan-flum" target="_blank">Matan Flum</a> (UCL, Bartlett Development Planning Unit).</p>

					<p>We are grateful for the supported provided by our sponsors:</p>
					<Logos />
					<h3>Contact Us:</h3>
					<ul>
						<li>Dr Haim Abraham – <a href="mailto:haim.abraham@ucl.ac.uk">haim.abraham@ucl.ac.uk</a></li>
						<li>Prof. Gilat Bachar - <a href="mailto:gilat.bachar@temple.edu">gilat.bachar@temple.edu</a></li>
						<li>Ceasefire Centre for Civilian Rights - <a href="contact@ceasefire.org">contact@ceasefire.org</a></li>
					</ul>
					<br/>
					<p>Website design and development by <a target="_blank" href="https://rectangle.design">Rectangle</a></p>
				</div>
				<div className={style.imageCaption}>© Dominika Zara/Shutterstock</div>


			</div>
		</div>
	)
}


const AboutHebrew = props => {
	return (
		<div className={style.page}>
			<div className={style.hero}>
				<div className={style.heroimage}><img src={image}/></div>
				<div className={style.heroblur}><img src={image}/></div>
			</div>


			<div className={style.pageInner}>
				<div className={style.imageCaption}></div>
				<div className={style.pageText}>
					<h1>אודות</h1>
					<p>המפה האינטראקטיבית מציגה מעל 450 תיקים של בתי משפט ישראליים במקרים בהם אזרחים הגישו תביעת נזיקין לפיצוי על פגיעה בחיים, בגוף, או ברכוש על נזקים שנגרמו להם במהלך פעולות מלחמתיות. ברוב המקרים אזרחים פלסטינים נפגעו על ידי כוחות הביטחון של ישראל ברצועת עזה ובגדה המערבית. אולם, מאגר המידע כולל גם תביעות של בעלי אזרחויות אחרות, וכן של אזרחים ישראליים שפעלו על מנת שפיצויים יושתו כנגד החמאס או הרשות הפלסטינית בגין נזקים שנגרמו עקבו פעילות מזוינת.</p>
					<p>מאגרי מידע המתעדים נזקים שנגרמו לאזרחים במהלך פעולות מלחמתיות הם בעלי חשיבות רבה לקידום פיצויים ויישוב סכסוכים. הנגשת הטרגדיות האישיות נותנת קול לאוכלוסיות פגיעות. איגודן תחת מקור אחד מסייע להבנת גודל האובדן שפעולות מלחמתיות משיתות על קהילות וטיבו של הנזק. תיעוד העוולות הנגרמות במהלך לחימה תורם ליכולת להטיל אחריות, והטלת אחריות ופיצויים מסייעת למאמצי הפיוס בין הקורבנות למזיקים.</p>
					<p>בהקשר של פיצויים עבור נזקים שאזרחים סובלים מהם בזמן פעולות מלחמתיות וכיבוש, ישראל מציעה נקודת מבט ייחודית. מספר התיקים בהם בתי משפט ישראליים בחנו את שאלת אחריות המדינה בנזיקין לפעולות מלחמתיות עולה על מספר התיקים המשולב שנדונו באוסטרליה, אנגליה, ארצות הברית וקנדה. בנוסף, התיקים בישראל שופכים אור על התפיסה המשפטית והמוסדית של לחימה אורבנית ופעולות למניעת טרור כמו-גם על השינויים בתפיסה וביחס של הסכסוך הישראלי-פלסטיני לאורך זמן.</p>
					<p>פרויקט זה הוא שיתוף פעולה בין ד"ר <a href="https://www.ucl.ac.uk/laws/people/haim-abraham" target="_blank">חיים אברהם</a> (UCL Faculty of Laws), <a href="https://law.temple.edu/contact/gilat-juli-bachar/" target="_blank">פרופ' גילת</a> בכר (Temple University Beasley School of Law)), וארגון <a href="https://www.ceasefire.org/" target="_blank">Ceasefire Centre for Civilian Rights</a>. עוזר המחקר לפרויקט הוא מר <a href="https://www.ucl.ac.uk/bartlett/development/people/matan-flum" target="_blank">מתן פלום</a> (UCL, Bartlett Development Planning Unit).</p>
					<p>אנו מודים למממנים שלנו:</p>
					<Logos />
					<h3>צרו קשר:</h3>
					<ul>
						<li>Dr Haim Abraham – <a href="mailto:haim.abraham@ucl.ac.uk">haim.abraham@ucl.ac.uk</a></li>
						<li>Prof. Gilat Bachar - <a href="mailto:gilat.bachar@temple.edu">gilat.bachar@temple.edu</a></li>
						<li>Ceasefire Centre for Civilian Rights - <a href="contact@ceasefire.org">contact@ceasefire.org</a></li>
					</ul>
					<br/>
					<p>עיצוב ופיתוח אתרי אינטרנט על ידי <a target="_blank" href="https://rectangle.design">Rectangle</a></p>
				</div>
				<div className={style.imageCaption}>© Dominika Zara/Shutterstock</div>
			</div>
		</div>
	)
}

const AboutArabic = props => {
	return (
		<div className={style.page}>
			<div className={style.hero}>
				<div className={style.heroimage}><img src={image}/></div>
				<div className={style.heroblur}><img src={image}/></div>
			</div>


			<div className={style.pageInner}>
				<div className={style.imageCaption}></div>
				<div className={style.pageText}>
					<h1>نبذة عن الموضوع</h1>
					<p>توضح الخريطة التفاعلية أكثر من 450 دعوى قضائية إسرائيلية التمس فيها المدنيون التعويض بموجب قانون المسؤولية التقصيرية عن الخسائر في الأرواح والأضرار الجسدية والأضرار في الممتلكات الناتجة أثناء النزاعات المسلحة. تنطوي الغالبية العظمى من الدعاوى القضائية على فلسطينيين أحدثت بهم قوات الأمن الإسرائيلية إصابات في قطاع غزة والضفة الغربية. إلا أن قواعد البيانات تتضمن دعاوى قضائية لأشخاص يحملون جنسيات أخرى، بالإضافة إلى دعاوى قضائية رفعها مدنيون إسرائيليون ضد حماس والسلطة الفلسطينية لمطالبتهما بتعويضات عن الأضرار المتكبدة أثناء الأعمال العدائية.</p>
					<p>قواعد البيانات التي توثق الخسائر والأضرار التي لحقت بالمدنيين أثناء النزاعات المسلحة بالغة الأهمية لتيسير عمليات جبر الضرر والمصالحة. إن سرد القصص الفردية يقدم فرصة للشعوب المستضعفة كي تعبر عن نفسها ويسمح تجميع هذه الروايات في مصدر واحد بأن تصبح  ظاهرة للعيان طبيعة ما ألحقه النزاع المسلح بالمجتمع المحلي من خسائر وأضرار والمدى الذي بلغته. إن الحصول على رواية واضحة للأخطاء التي ارتُكِبَت أثناء النزاع لهو أمر أساسي من أجل محاسبة المسؤولين عنها، والخضوع للمحاسبة وعمليات جبر الضرر هي أمور أساسية من أجل المصالحة بين من عانوا من الخسائر والأضرار نتيجة للأخطاء ومن ارتكبوها.</p>
					<p>وفي سياق عمليات جبر الضرر الذي لحق بالمدنيين أثناء النزاع المسلح والاحتلال، تقدم إسرائيل منظورًا فريدًا. إن عدد الدعاوى القضائية التي تنظر فيها المحاكم الإسرائيلية مسائل تتعلق بمسؤولية الدولة بموجب قانون المسؤولية التقصيرية عن الأضرار التي يُزعَم أن الجيش الإسرائيلي قد تسبب فيها تتجاوز عدد الدعاوى القضائية المشابهة في أستراليا وكندا والمملكة المتحدة والولايات المتحدة الأمريكية مجتمعة. تقدم الدعاوى القضائية الإسرائيلية لمحة فريدة عن الأساليب القضائية المؤسسية للتعامل مع حروب المدن وأعمال مكافحة الإرهاب، بالإضافة إلى تطور المواقف المتخذة إزاء النزاعات طويلة الأمد مع مرور الزمن.</p>
					<p>هذا المشروع هو نتاج التعاون بين الدكتور/ <a href="https://www.ucl.ac.uk/laws/people/haim-abraham" target="_blank">حاييم أبراهام</a> (كلية الحقوق بكلية لندن الجامعية)، والأستاذة/ <a href="https://law.temple.edu/contact/gilat-juli-bachar/" target="_blank">جيلات بشار</a> (كلية بيزلي للقانون بجامعة تمبل)، بالتعاون مع <a href="https://www.ceasefire.org/" target="_blank">مركز وقف إطلاق النار للحقوق المدنية</a>. وعاوننا السيد/ <a href="https://www.ucl.ac.uk/bartlett/development/people/matan-flum" target="_blank">ماتان فلوم</a> (كلية لندن الجامعية، وحدة التخطيط التنموي في كلية بارتليت).</p>
					<p>ونحن ممتنون للدعم الذي تلقيناه من الجهات الراعية للمشروع:</p>
					<Logos />
					<h3>للتواصل معنا:</h3>
					<ul>
						<li>دكتور/ حاييم أبراهام – <a href="mailto:haim.abraham@ucl.ac.uk">haim.abraham@ucl.ac.uk</a></li>
						<li>أستاذة/ جيلات بشار - <a href="mailto:gilat.bachar@temple.edu">gilat.bachar@temple.edu</a></li>
						<li>مركز وقف إطلاق النار للحقوق المدنية - <a href="contact@ceasefire.org">contact@ceasefire.org</a></li>
					</ul>
					<br/>
					<p>تصميم وتطوير المواقع الإلكترونية بواسطة <a target="_blank" href="https://rectangle.design">Rectangle</a></p>
				</div>
				<div className={style.imageCaption}>© Dominika Zara/Shutterstock</div>
			</div>
		</div>
	)
}

const Logos = props => {
	return (
		<div>

			
			<div className={style.logos}>
				<div><a href="https://www.ucl.ac.uk/public-policy/ucl-public-policy" target="_blank"><img src={ucl}/></a></div>
				<div><a href="https://www.ucl.ac.uk/laws/ucl-faculty-laws" target="_blank"><img src={ucllaws}/></a></div>
				<div><a href="https://www.ukri.org/councils/research-england/" target="_blank"><img src={ukri}/></a></div>
				<div><a href="https://www.minerva.mpg.de/centers/list/minerva-center-for-the-rule-of-law-and-extreme-conditions" target="_blank"><img src={minerva}/></a></div>
			</div>
		</div>

	)
}

